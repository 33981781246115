import { ethers } from 'ethers'
import { NextApiRequest, NextApiResponse } from 'next'

export type Profile = {
	name?: string
	user_name?: string
	email?: string
	twitter_handle?: string
	profile_pic_url?: string
	cover_pic_url?: string
	gradient_1: string
	gradient_2: string
	bio?: string
}

export type FollowProfile = {
	name?: string
	user_name?: string
	profile_pic_url?: string
	gradient_1: string
	gradient_2: string
	wallet_address: string
}

export enum OnboardingStatusKeys {
	TOKEN_CREATED = 'TOKEN_CREATED',
	AIRDROP_CREATED = 'AIRDROP_CREATED',
	QUEST_CREATED = 'QUEST_CREATED',
	VEST_CREATED = 'VEST_CREATED',
}
export interface User {
	id: string
	createdAt: Date
	updatedAt: Date
	name?: any
	user_name?: string
	email?: any
	twitter_handle?: string
	discord_invite?: string
	mirror_profile?: string
	github_profile?: string
	snapshot_space?: string
	reddit_page?: string
	primaryTokenAddress?: string
	primaryTokenChain?: string
	is_featured?: boolean
	wallet_address: string
	profile_pic_url?: any
	cover_pic_url?: any
	nonce: string
	gradient_1: string
	gradient_2: string
	bio?: string
	is_verified: boolean
	following?: number
	followers?: number
	has_reward_mainnet: boolean
	has_reward_ropsten: boolean
	has_reward_matic: boolean
	has_reward_mumbai: boolean
	hasVestBeta: boolean
	hasMembershipBeta: boolean
	insta_handle?: string
	ens_name?: string
	website_url?: string
	is_onboarding_complete: boolean
	onboarding_status: Record<OnboardingStatusKeys, boolean>
	tags?: Tag[]
}

export interface Tag {
	id: string
	createdAt: string
	updatedAt?: string
	name: string
}

export enum NotificationType {
	FOLLOW = 'follow',
	REWARD_APPLY = 'reward_apply',
	REWARD_APPLY_RESPONSE = 'reward_apply_response',
	TIP = 'TIP',
	AIRDROP_RECEIVED = 'airdrop_received',
	AIRDROP_STOPPED = 'airdrop_stopped',
	RECEIVED_TOKENS_VESTING = 'received_tokens_vesting',
	TOKEN_MINTED = 'token_minted',
	AIRDROP_PENDING = 'airdrop_pending',
	AIRDROP_SUCCESS = 'airdrop_success',
	AIRDROP_FAILED = 'airdrop_failed',
	TIER_PURCHASE_SUCCESS = 'tier_purchase_success',
	TIER_CREATION_SUCCESS = 'tier_creation_success',
	TIER_MEMBER_GAINED = 'tier_member_gained',
}

export interface GenericNotification {
	id: string
	createdAt: string
	updatedAt?: string
	read: boolean
	reciever_address: string
	notifier_address: string
	notifier: User
}

export type Notification = GenericNotification &
	(
		| {
				type: NotificationType
				metadata: {
					[k: string]: string
				}
		  }
		| {
				type: NotificationType.AIRDROP_RECEIVED
				metadata: {
					explorerLink: string
					amount?: string
					tokenId?: string
					tokenSymbol: string
					tokenType?: TokenType
				}
		  }
		| {
				type: NotificationType.RECEIVED_TOKENS_VESTING
				metadata: {
					tokenSymbol: string
					total: string
				}
		  }
		| {
				type: NotificationType.REWARD_APPLY
				metadata: {
					reward_name: string
					reward_application_id: string
				}
		  }
		| {
				type: NotificationType.AIRDROP_STOPPED
				metadata: {
					description: string
					slug: string
				}
		  }
		| {
				type: NotificationType.TOKEN_MINTED
				metadata: {
					tokenSymbol: string
					explorerLink: string
					supply: string
				}
		  }
		| {
				type: NotificationType.AIRDROP_PENDING
				metadata: {
					airdropId: string
					description: string
					tokenSymbol: string
					amount_per_recipient: string
					number_of_recipients: string
					txHash: string
					network_chain_id: string
				}
		  }
		| {
				type: NotificationType.AIRDROP_SUCCESS
				metadata: {
					airdropId: string
					description: string
					tokenSymbol: string
					amount_per_recipient: string
					number_of_recipients: string
					claimLink: string
					txHash: string
					network_chain_id: string
				}
		  }
		| {
				type: NotificationType.AIRDROP_FAILED
				metadata: {
					airdropId: string
					description: string
					tokenSymbol: string
					amount_per_recipient: string
					number_of_recipients: string
					txHash: string
					network_chain_id: string
				}
		  }
	)

export type Nft = {
	id?: string
	slug: string
	tokenId: string
	listingId: string
	owner: User
	creator: User
	creator_address: string
	name: string
	description: string
	price: string
	token: string
	symbol: string
	sold: boolean
	onsale: boolean
	pic: string
	metadata?: string
	file?: string
	fileType?: string
	likes?: any
	likes_count: number
	network_chain_id: number
	chainId: number
	category?: string
}

export interface IPill {
	title?: string
	text?: string
	footer?: string
	bgcolor?: string
}

export interface IMenu {
	orientation: 'vertical' | 'horizontal'
}

export interface IToken {
	name: string
	symbol: string
	supply: string
	holders: string
	volume: string
}

export interface ICampaign {
	id: string
	remoteCampaignId: number
	manager: string
	token: string
	linksAmount: number
	amountPerLink: number
	createdAt: string
	updatedAt: string
	links?: ILink[]
}

export type CurrentProfile = {
	user: User
	followings: FollowProfile[]
}

export type ProfileData = CurrentProfile & {
	followers: FollowProfile[]
}
export interface ILink {
	id: string
	slug: string
	token_addr: string
	createdAt: string
	updatedAt: string
	amount_per_recipient: number
	number_of_recipients: number
	user_addr: string
	remoteCampaignId: string
}

export interface IDeposit {
	id: string
	remoteDepositId: string
	owner_addr: string
	name: string
	owner: User
	token_addr: string
	symbol: string
	amount: number
	price: number
	description: string
	createdAt: string
	updatedAt: string
}

export interface IItem {
	id: string
	remoteItemId: number
	owner: string
	amount: number
	price: number
	title: string
	description: string
	type: string
	createdAt: string
	updatedAt: string
}

export interface Token {
	address: string
	name?: string
	symbol?: string
	network_chain_id: string
	decimals?: number
	tokenSupply?: string
	user_addr?: string
	id: string
	createdAt: string
	updatedAt: string
	type?: string
	slope?: any
	slopeDecimals?: any
	tokensDistributed?: number
}

export interface StaticToken {
	address?: string
	name?: string
	symbol?: string
	network_chain_id?: string
	decimals?: number
	tokenSupply?: string
	user_addr?: string
	type?: string
	image?: string
}

export interface Campaign {
	remoteCampaignId: string
	description?: string
	token_addr: string
	slug: string
	number_of_recipients: number
	amount_per_recipient: number
	user_addr: string
	id: string
	createdAt: Date
	updatedAt: Date
	network_chain_id: string
	txHash?: string
	whitelistedAddresses?: string[]
}

export interface CampaignWithDetails extends Campaign {
	user: User
	token: Token
	claims: Claim[]
}

export interface Reward {
	id: string
	createdAt: string
	updatedAt: string
	user_addr: string
	remote_reward_id: string
	name: string
	description: string
	allowed_addresses: string[]
	claimed_addresses: string[]
	active: boolean
	amount_per_recipient: number
	number_of_allowed_recipients: number
	network_chain_id: string
}

export interface RewardWithDetails {
	id: string
	createdAt: Date
	updatedAt: Date
	remote_reward_id: string
	user_addr: string
	user: User
	name: string
	description: string
	allowed_addresses: string[]
	claimed_addresses: string[]
	network_chain_id: string
	number_of_allowed_recipients: number | null
	token_addr: string
	token: Token
	amount_per_recipient: number
	active: boolean
	isGnosisSafeTx?: boolean
	gnosisSafeAddress?: string
}

export interface AddEthereumChainParameter {
	chainId: string // A 0x-prefixed hexadecimal string
	chainName: string
	nativeCurrency: {
		name: string
		symbol: string // 2-6 characters long
		decimals: 18
	}
	rpcUrls: string[]
	blockExplorerUrls?: string[]
	iconUrls?: string[] // Currently ignored.
}

export type NetworkName = 'polygon' | 'mumbai' | 'mainnet' | 'ropsten' | 'goerli' | 'unknown'

export type Contracts =
	| 'coinvise'
	| 'tokenDeployer'
	| 'erc721'
	| 'nftMarketplace'
	| 'nftProxy'
	| 'tokenLinearBondedEthDeployer'
	| 'erc721NFTMiami'
	| 'multisend'
export enum ContractsEnum {
	coinvise = 'coinvise',
	tokenDeployer = 'tokenDeployer',
	erc721 = 'erc721',
	nftMarketplace = 'nftMarketplace',
	nftProxy = 'nftProxy',
	tokenLinearBondedEthDeployer = 'tokenLinearBondedEthDeployer',
	// nftAirdrop = 'nftAirdrop',
	erc721NFTMiami = 'erc721NFTMiami',
	fxERC20RootTunnel = 'fxERC20RootTunnel',
	multisend = 'multisend',
	vestingFactory = 'vestingFactory',
	erc20Token = 'erc20Token',
	erc721GCR = 'erc721GCR',
	membershipsFactory = 'membershipsFactory',
	NFTAirdrop = 'NFTAirdrop',
}

export type NftsData = {
	nfts: Nft[]
	cursor: string
	nextCursor?: any
}

export enum NftCategoriesEnum {
	art = 'Art',
	photography = 'Photography',
	games = 'Games',
	metaverses = 'Metaverses',
	music = 'Music',
	domains = 'Domains',
	defi = 'DeFi',
	memes = 'Memes',
}

export enum NftCategoriesColorsEnum {
	Art = 'magenta',
	Photography = 'red',
	Games = 'volcano',
	Metaverses = 'orange',
	Music = 'gold',
	Domains = 'lime',
	Defi = 'green',
	Memes = 'blue',
}

export type UserResult = {
	users: User[]
	nextSkip: number
}

export interface createBulkType {
	tokenIds: string[]
	name: string
	description: string
	token: string
	symbol: string
	pic: string
	category: string
	metadata?: string
	file?: string
	fileType?: string
	network_chain_id: string
	price: string
	listingId: string
	onsale: boolean
}

export interface RewardApplication {
	id: string
	createdAt: Date
	updatedAt: Date
	reward: Reward
	reward_id: string
	user: User
	user_addr: string
	message: string
	response_message?: string
	status: RewardApplicationStatusEnum
}

export enum RewardApplicationStatusEnum {
	PENDING = 'PENDING',
	ACCEPTED = 'ACCEPTED',
	DECLINED = 'DECLINED',
}

export interface RewardResponse {
	application_id: string
	message?: string
	status: RewardApplicationStatusEnum
}

export type Multisend = {
	id: string
	createdAt: Date
	updatedAt: Date
	sender?: User
	senderAddr: string
	token?: Token
	tokenAddr: string
	addresses: string[]
	txHash: string
	network_chain_id: string
	description: string
	amounts?: string[]
}

export interface CampaignFull extends Campaign {
	token: Token
	user: User
}

export type Send = (CampaignFull & { type: 'airdrop' }) | (Multisend & { type: 'multisend' })

export type SendData = {
	data: Send[]
	nextFrom?: number
}

export interface ExtraTokenData {
	allowance?: ethers.BigNumber // will be very large number if eth is being sent
	userBalance: ethers.BigNumber
	symbol: string
	tokenDecimals: ethers.BigNumber
	preDecimals: number
}

export type Nullable<T> = T | null

export type NextSentryApiHandler = (req: NextApiRequest, res: NextApiResponse) => Promise<void>

export interface NotificationResult {
	data: Notification[]
	nextSkip?: number
}

// * Leaderboard data
export interface LeaderBoardConvoUser {
	_id: string // address
	_mod: number
	asyncart?: Asyncart
	brightId?: boolean
	coinvise?: Coinvise
	cryptoScamDb?: boolean
	deepdao?: number
	ens: boolean
	foundation?: Foundation
	idena?: boolean
	knownorigin?: Knownorigin
	mirror?: boolean
	poap?: number
	poh?: boolean
	rabbitHole?: number
	rarible?: Rarible
	score?: number
	success: boolean
	superrare?: Superrare
	uniswapSybil: number
	unstoppableDomains: boolean
	coinviseScore?: number
}

export type LeaderBoardUser = LeaderBoardConvoUser &
	Pick<User, 'ens_name' | 'profile_pic_url' | 'gradient_1' | 'gradient_2' | 'user_name'> & {
		rank: number
		address: string
		reputation: number
	}

export type Asyncart = {
	totalAmountSold: number
	totalCountSold: number
}

export type Coinvise = {
	airdropCount: number
	multisendCount: number
	nftsCreated: number
	tokensCreated: number
	totalAmountSold: number
	totalCountSold: number
	totalPoolCount: number
	totalPoolTvl: number
}

export type Foundation = {
	totalAmountSold: number
	totalCountSold: number
}

export type Knownorigin = {
	totalAmountSold: number
	totalCountSold: number
}

export type Rarible = {
	totalAmountSold: number
	totalCountSold: number
}

export type Superrare = {
	totalAmountSold: number
	totalCountSold: number
}

export type Zora = {
	totalAmountSold: number
	totalCountSold: number
}

export type ReputationScore = {
	_id: string
	_mod: number
	asyncart: Asyncart
	brightId: boolean
	coinvise: Coinvise
	cryptoScamDb: boolean
	deepdao: number
	ens: string
	foundation: Foundation
	idena: boolean
	knownorigin: Knownorigin
	mirror: boolean
	poap: number
	poh: boolean
	rabbitHole: number
	rarible: Rarible
	score: number
	success: boolean
	superrare: Superrare
	uniswapSybil: number
	unstoppableDomains: boolean
	zora: Zora
	gitcoin: { funder: boolean }
}

//----------------------------- Use Top Supporters Type -----------------------------

export interface TopSupporterItem {
	contract_decimals: number
	contract_name: string
	contract_ticker_symbol: string
	contract_address: string
	supports_erc?: any
	logo_url: string
	address: string
	balance: string
	total_supply: string
	block_height: number
}

export interface TopSupportersPagination {
	has_more: boolean
	page_number: number
	page_size: number
	total_count: number
}

export interface TopSupportersData {
	updated_at: string
	items: TopSupporterItem[]
	pagination: TopSupportersPagination
}

export interface TopSupporterItemWithUser extends TopSupporterItem {
	rank: number
	user?: User
	percentShare: number
}

export interface EmailCampaign {
	id: string
	createdAt: string
	updatedAt: string
	remoteCampaignId: string
	token_addr: string
	token: Token
	amountPerRecipient: number
	user_addr: string
	user: User
	network_chain_id: string
	description: string
	claims: EmailClaim[]
}

export interface EmailCampaignWithClaimUsers extends Omit<EmailCampaign, 'claims'> {
	claims: EmailClaimWithUser[]
}

export interface EmailClaim {
	id: string
	createdAt: string
	updatedAt: string
	email: string
	isClaimed: boolean
	user_addr?: string
	campaignId: string
}

export interface EmailClaimWithUser {
	user?: User
}
export interface EmailClaimExtended extends EmailClaim {
	slug: string
	campaign: EmailCampaign
}

export interface DefaultModalProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
}

export interface COVALENT__TokenHoldersResult {
	data: {
		items: TopSupporterItem[]
		pagination: {
			has_more: boolean
			page_number: number
			page_size: number
			total_count: 2
		}
	}
}

export enum TokenBridgeStatus {
	PENDING = 'PENDING',
	SUCCESSFUL = 'SUCCESSFUL',
	FAILED = 'FAILED',
}

export interface Bridge {
	id: string
	createdAt: Date | string
	updatedAt: Date | string
	// rootToken: Token
	rootTokenAddress: string
	rootTokenChain: string
	// childToken: Token
	childTokenAddress: string
	childTokenChain: string
	// user: User
	userAddress: string
	status: TokenBridgeStatus
}
export interface Vest {
	address: string
	id: string
	createdAt: Date
	updatedAt: Date
	startDate: Date
	cliffPeriod: number
	vestPeriod: number
	totalTokens: number
	beneficiaryAddress: string
	creatorAddress: string
	tokenAddress: string
	tokenChain: string
	token?: Token
	creator?: User
	beneficiary?: User
	description?: string
}

export interface CustomModalProps {
	isOpen: boolean
	onClose: () => void
}

export enum TokenType {
	ERC721 = 'ERC721',
	ERC1155 = 'ERC1155',
	ERC20 = 'ERC20',
}

export interface StoreMultisend {
	tokenAddr: string
	addresses: string[]
	amounts?: string[]
	tokenIds?: string[]
	tokenType: TokenType
	txHash: string
	network_chain_id: string
	description: string
}

export type TypedLogDescription<Event extends ethers.Event> = ethers.utils.LogDescription & Pick<Event, 'args'>
export interface Domain {
	name: string
	resolvedAddress: { id: string }
}

export interface ENSResolver {
	data: {
		domains: Domain[]
	}
}

export enum AirdropConditionType {
	NFT = 'NFT',
	TOKENS = 'TOKENS',
	WHITELIST = 'WHITELIST',
	POAP = 'POAP',
}

export type NFTCondition = {
	type: AirdropConditionType.NFT
	metadata: {
		address: string
		name?: string
		symbol?: string
		chainId: number
	}
}

export type TokenCondition = {
	type: AirdropConditionType.TOKENS
	metadata: {
		address: string
		name?: string
		symbol?: string
		minAmount: string
		chainId: number
	}
}

export type WhitelistCondition = {
	type: AirdropConditionType.WHITELIST
	metadata: {
		addresses: string[]
	}
}

export type PoapCondition = {
	type: AirdropConditionType.POAP
	metadata: {
		eventId: number
		image: string
		name: string
	}
}

export type Condition = ConditionDTO & {
	id: string
	airdropId: string
	createdAt: string
	updatedAt: string
}

export type ConditionDTO = NFTCondition | TokenCondition | WhitelistCondition | PoapCondition

export type Operator = 'AND' | 'OR'

export interface Claim {
	user_addr: string
	user: User
	airdropId: string
	txHash: string
}

export interface AirdropUniqueLink {
	id: string
	createdAt: string
	updatedAt: string
	slug: string
	remoteCampaignId: string
	token_addr: string
	token: Token
	user_addr: string
	user: User
	number_of_recipients: number
	amount_per_recipient: number // currently returns string, will be fixed
	txHash: string
	description?: string
	operator?: Operator
	conditions?: Array<Condition>
	claims: Array<Claim>
	network_chain_id: number
	active: boolean
	hasCoverImage: boolean
	cover_color: string
	isGnosisSafeTx?: boolean
	gnosisSafeAddress?: string
	txStatus?: TxStatus
}

export type AirdropUniqueLinkDTO = Pick<
	AirdropUniqueLink,
	| 'token_addr'
	| 'remoteCampaignId'
	| 'number_of_recipients'
	| 'amount_per_recipient'
	| 'txHash'
	| 'description'
	| 'operator'
	| 'txStatus'
> & {
	conditions: Array<ConditionDTO>
}

export type PastAirdrop =
	| {
			__typename: 'WalletList'
			data: Multisend
	  }
	| {
			__typename: 'UniqueLink'
			data: AirdropUniqueLink
	  }

export interface PoapEventDetails {
	id: number
	fancy_id: string
	name: string
	event_url: string
	image_url: string
	country: string
	city: string
	description: string
	year: number
	start_date: string
	end_date: string
	expiry_date: string
	created_date: string
	from_admin: boolean
	virtual_event: boolean
	event_template_id: number
	event_host_id: number
	private_event: boolean
}

export enum TxStatus {
	PENDING = 'pending',
	SUCCESS = 'success',
	FAILED = 'failed',
}

export interface Owner {
	user_name?: string
	profile_pic_url?: string
	cover_pic_url?: string
	gradient_1: string
	gradient_2: string
	wallet_address: string
}

export interface Membership {
	id: string
	owner: Owner
	contractAddress: string
	price: string
	cap: string
	validity: string
	quantity: string // No. of purchases
	title: string
	address: string
	image: string
	benefits: string[]
	baseTokenURI: string
	archived: boolean
	network_chain_id: string
	isGnosisSafeTx: boolean
	gnosisSafeAddress?: string // Only for membership-by-address
	user_addr: string
	airdropAmount: string
	airdropToken: string
}

export interface MembershipUsers {
	id: string
	membership: Membership
	memberAddress: string
	expirationTimestamp: string
	tokenId: string
	user_name: string
	profile_pic_url: string
	cover_pic_url?: any
}

export interface InfiniteMembershipResponse {
	data: MembershipUsers[]
	first: string
	skip: string
}

export interface InfiniteMembershipsByOwnerResponse {
	data: Membership[]
	first: string
	skip: string
}

export interface SaveMembershipDetailsResponse {
	title: string
	address: string
	price: string
	quantity: number
	validity: number
	image: string
	benefits: string[]
	benefitsIpfs: string
	archived: boolean
	network_chain_id: string
	user_addr: string
	gnosisSafeAddress?: any
	id: string
	createdAt: Date
	updatedAt: Date
	isGnosisSafeTx: boolean
}
