import { CheckIcon, CrossIcon, SpinnerIcon } from '@components/icons'
import clsx from 'clsx'
import Link, { LinkProps } from 'next/link'
import { forwardRef, MouseEvent, PropsWithChildren } from 'react'

/**
 * Props common to Link and Button
 */
type BaseButtonProps = PropsWithChildren<{
	/**
	 * Allows for custom styling/overriding styles, preferably Tailwind CSS only
	 * Merges and overrides the default styles
	 */
	className?: string
	/**
	 * For applying different styles on the button
	 * @default "solid"
	 */
	variant?: 'solid' | 'outlined' | 'solidInverted'
	/**
	 * If 'sm' the button width will only be as wide as its contents, otherwise it grows to the full width of the parent
	 */
	size?: 'sm'
}>

/**
 * Props specific to the Link Button
 */
interface LinkButtonProps extends BaseButtonProps, Omit<LinkProps, 'as' | 'href'> {
	href?: LinkProps['href']

	// TODO: add prop to handle opening link in a new tab
}

/**
 * Props specific to the Button
 * 
 // TODO: add HTMLButton props to allow more use cases -- like accessibility
 */
interface OnlyButtonProps extends BaseButtonProps {
	/**
	 * HTML attribute type, 'submit' is useful for <form>, otherwise default 'button' is fine
	 * @default "button"
	 */
	type?: 'button' | 'submit' | 'reset'
	/**
	 * If 'true', button becomes unclickable and hover styles are disabled
	 */
	disabled?: boolean
	/**
	 * Content for the button in disabled state
	 * Defaults to original text
	 */
	disabledContent?: string
	/**
	 * Content for the button in loading state
	 * Defaults to original text
	 */
	loadingContent?: string
	/**
	 * Content for the button in success state
	 * Defaults to original text
	 */
	successContent?: string
	/**
	 * Content for the button in failed state
	 * Defaults to original text
	 */
	failedContent?: string
	/**
	 * If 'true' the solid button will show a spinner and become unclickable
	 */
	isLoading?: boolean
	/**
	 * If 'true' the solid button will show a check and become unclickable
	 */
	isSuccess?: boolean
	/**
	 * If 'true the solid button will show a red X and become unclickable
	 */
	isFailed?: boolean
	/**
	 * Passing an function to be called when button is clicked
	 */
	onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void | Promise<any>

	// Used for discriminated union
	href?: undefined
}

type ButtonProps = OnlyButtonProps | LinkButtonProps

/**
 * Type guard to determine if the props are for a Link or a Button
 */
const isLink = (props: ButtonProps): props is LinkButtonProps => 'href' in props
const isButton = (props: ButtonProps): props is OnlyButtonProps => !('href' in props)

// TODO: need to style button press state (active)

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant = 'solid', size, ...rest }, ref) => {
		const baseStyle = clsx('button-base', size === 'sm' && 'button-small')
		const variantStyles =
			variant === 'outlined' ? 'button-outlined' : variant === 'solid' ? 'button-solid' : 'button-solid-inverted'

		if (isLink(rest)) {
			return (
				<Link href={rest.href} {...rest}>
					<a
						// TODO: support ref for Link
						className={clsx(baseStyle, variantStyles, className, 'hover:text-current')}
					>
						{rest.children}
					</a>
				</Link>
			)
		}

		if (isButton(rest)) {
			return (
				<button
					ref={ref}
					type={rest.type || 'button'}
					disabled={rest.disabled || rest.isSuccess || rest.isFailed}
					onClick={async (event) => {
						if (!rest.onClick || rest.isSuccess || rest.isLoading || rest.isFailed) return

						await rest.onClick(event)
					}}
					className={clsx(
						baseStyle,
						variantStyles,
						{ 'button-result': rest.isSuccess || rest.isFailed },
						{ 'button-loading': rest.isLoading && !rest.disabled },
						className
					)}
				>
					{rest.children && (
						<span className="flex flex-row items-center justify-center">
							{
								// Disbaled
								rest.disabled
									? rest.disabledContent ?? rest.children
									: // Success
									rest.isSuccess
									? rest.successContent ?? rest.children
									: // Failed
									rest.isFailed
									? rest.failedContent ?? rest.children
									: // Loading
									rest.isLoading
									? rest.loadingContent ?? rest.children
									: rest.children
							}
						</span>
					)}
					{variant === 'solid' ? (
						rest.isSuccess ? (
							<div className="flex ml-2">
								<CheckIcon className="w-[18px] h-[18px] stroke-yes" />
							</div>
						) : rest.isFailed ? (
							<div className="flex ml-2">
								<CrossIcon className="w-[18px] h-[18px] fill-no" />
							</div>
						) : (
							rest.isLoading &&
							!rest.disabled && (
								<div className="flex ml-2">
									<SpinnerIcon className="w-6 h-6 fill-yes animate-spin" />
								</div>
							)
						)
					) : null}
				</button>
			)
		}

		return null
	}
)

Button.displayName = 'Button'
