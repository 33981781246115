import useComponentVisible from '@hooks/useComponentVisible'
import clsx from 'clsx'
import { FC, KeyboardEvent } from 'react'

interface DropdownProps {
	className?: string
	options: string[]
	setOption?: (option: string) => void | Promise<any>
	currentOption: string
}

// TODO: refactor into Caret icons
// Stroke
const DownCaretIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9 1.5L5 5.5L1 1.5" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export const Dropdown: FC<DropdownProps> = ({ className, options, setOption, currentOption }) => {
	const [ref, showOptions, setShowOptions] = useComponentVisible(false)

	const handleOptionSelection = (option): void => {
		setOption(option)
	}

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
		if (e.key === ' ' || e.key === 'Enter') {
			// Prevents accidental collapsing when toggling buttons within the component
			if (e.target === e.currentTarget) {
				e.preventDefault()
				toggleOptions()
			}
		}
	}

	const toggleOptions = (): void => setShowOptions((show) => !show)

	return (
		<div
			ref={ref}
			onKeyDown={onKeyDown}
			tabIndex={0}
			role="button"
			onClick={toggleOptions}
			className={clsx(className, 'min-w-fit relative px-3')}
		>
			<div className="flex flex-row items-center gap-2 justify-center w-20">
				<span className="select-none text-color-6 font-medium text-[18px]">{currentOption}</span>
				<span className="shrink-0 flex justify-center items-center w-5 h-5 rounded-full bg-color-2">
					<DownCaretIcon className="stroke-color-6 w-2 h-2" />
				</span>
			</div>
			{showOptions && (
				<div
					className={clsx(
						'z-[1] bg-color-2 absolute left-0 flex-col gap-5 divide-y divide-color-3 w-full min-w-min rounded-b-2xl'
					)}
				>
					{options.map((option, i) => (
						<button
							className="select-none p-2 hover:bg-color-3 hover:text-color-7 text-color-6 w-full last:rounded-b-2xl"
							key={i}
							onClick={() => handleOptionSelection(option)}
						>
							<span>{option}</span>
						</button>
					))}
				</div>
			)}
		</div>
	)
}
