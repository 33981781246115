import clsx from 'clsx'
import { FC } from 'react'

// Stroke
export const RightArrowIcon: FC<{ className?: string }> = ({ className }) => (
	<svg className={className} width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.5 1.5293L19 7.0293L13.5 12.5293" strokeWidth="2" strokeLinecap="round" />
		<path d="M18.9422 7.0293H1.21191" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export const LeftArrowIcon: FC<{ className?: string }> = ({ className }) => (
	<svg
		className={clsx(className, 'rotate-180')}
		width="21"
		height="14"
		viewBox="0 0 21 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M13.5 1.5293L19 7.0293L13.5 12.5293" strokeWidth="2" strokeLinecap="round" />
		<path d="M18.9422 7.0293H1.21191" strokeWidth="2" strokeLinecap="round" />
	</svg>
)
