import { FC } from 'react'

export const HexStatsIcon: FC<{ className?: string }> = ({ className }) => (
	<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9631 1.00152L11.9796 1.00211L11.996 1.00216C12.4774 1.00351 12.9261 1.08314 13.3075 1.25012C14.6926 2.0186 16.0637 2.79754 17.4399 3.57937C18.2966 4.06605 19.1553 4.55386 20.0205 5.04095L20.0561 5.06103L20.0933 5.07813C21.2211 5.59662 22.0314 6.61338 22.1566 7.72539C22.1708 9.28484 22.1686 10.8373 22.1664 12.3959C22.1651 13.3673 22.1637 14.3411 22.1663 15.3204L22.1664 15.3626L22.1701 15.4046C22.2747 16.6042 21.7908 17.8019 20.8594 18.4719C19.4881 19.2649 18.1145 20.0406 16.7358 20.8192C15.879 21.303 15.0203 21.788 14.1589 22.2789L14.1232 22.2992L14.0893 22.3224C13.0702 23.0197 11.7533 23.2081 10.687 22.7484C9.3033 21.9808 7.93393 21.2025 6.55934 20.4212C5.70226 19.9341 4.84315 19.4458 3.9773 18.9583L3.94168 18.9382L3.90454 18.9211C2.77655 18.4022 1.96627 17.3856 1.84121 16.2737C1.82706 14.7145 1.82921 13.1622 1.83137 11.6038C1.83272 10.6322 1.83407 9.65818 1.83148 8.67866L1.83137 8.63769L1.82791 8.59687C1.73175 7.46222 2.14932 6.33637 2.98497 5.64454C4.06201 4.97453 5.1462 4.36931 6.25357 3.75116C6.93583 3.37031 7.62688 2.98455 8.33046 2.5757C8.68887 2.39223 9.03458 2.18626 9.35 1.99823L9.3561 1.99459C9.68658 1.79758 9.98557 1.61934 10.2873 1.4638C10.8893 1.15354 11.4143 0.981881 11.9631 1.00152Z"
			strokeWidth="2"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.63281 11.8906L8.63281 16.5853L6.38281 16.5853L6.38281 11.8906L8.63281 11.8906Z"
			stroke="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.125 6.26172L13.125 16.5882L10.875 16.5882L10.875 6.26172L13.125 6.26172Z"
			stroke="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.6172 9.53125L17.6172 16.5869L15.3672 16.5869L15.3672 9.53125L17.6172 9.53125Z"
			stroke="none"
		/>
	</svg>
)

export const HexGlitterIcon: FC<{ className?: string }> = ({ className }) => (
	<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9631 1.00152L11.9796 1.00211L11.996 1.00216C12.4774 1.00351 12.9261 1.08314 13.3075 1.25012C14.6926 2.01862 16.0638 2.79758 17.44 3.57943C18.2967 4.0661 19.1553 4.55388 20.0205 5.04095L20.0561 5.06103L20.0933 5.07813C21.2211 5.59662 22.0314 6.61338 22.1566 7.72539C22.1708 9.2849 22.1686 10.8374 22.1664 12.3961C22.1651 13.3674 22.1637 14.3412 22.1663 15.3204L22.1664 15.3626L22.1701 15.4046C22.2747 16.6042 21.7908 17.8019 20.8594 18.4719C19.4881 19.2648 18.1145 20.0406 16.7358 20.8192C15.879 21.303 15.0203 21.788 14.1589 22.2789L14.1232 22.2992L14.0893 22.3224C13.0702 23.0197 11.7533 23.2081 10.687 22.7484C9.30331 21.9808 7.93393 21.2025 6.55934 20.4212C5.70226 19.9341 4.84315 19.4458 3.9773 18.9583L3.94168 18.9382L3.90454 18.9211C2.77655 18.4022 1.96627 17.3856 1.84121 16.2737C1.82706 14.7145 1.82921 13.1622 1.83137 11.6038C1.83272 10.6322 1.83407 9.65818 1.83148 8.67866L1.83137 8.63769L1.82791 8.59687C1.73175 7.46222 2.14932 6.33638 2.98496 5.64454C4.06201 4.97453 5.14621 4.36931 6.25357 3.75116C6.93583 3.3703 7.62688 2.98455 8.33046 2.5757C8.68887 2.39223 9.03458 2.18626 9.35 1.99823L9.35612 1.99458C9.68659 1.79757 9.98557 1.61933 10.2873 1.4638C10.8893 1.15354 11.4143 0.981881 11.9631 1.00152Z"
			strokeWidth="2"
			fill="none"
		/>
		<path
			d="M17.9838 12.0007C12.9809 12.5378 12.5358 12.9828 11.9987 17.9857C11.4616 12.9828 11.0319 12.5378 6.01367 12.0007C11.0319 11.4636 11.4616 11.0185 11.9987 6.01562C12.5358 11.0185 12.9809 11.4636 17.9838 12.0007Z"
			stroke="none"
		/>
	</svg>
)
