import clsx from 'clsx'
import { FC } from 'react'

interface ToggleSwitchProps {
	handleToggle: () => void
	toggled: boolean | null
	toggleOnLabel: string
	toggleOffLabel: string
	size: 'sm' | 'md' | 'lg'
	buttonPadding?: string
	labelPadding?: string
	variant?: 'switch' | 'option'
}

const transitionStyle = 'absolute transform transition duration-300'

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
	handleToggle,
	toggled,
	toggleOnLabel,
	toggleOffLabel,
	size,
	buttonPadding = 'mx-1',
	labelPadding = 'mx-2',
	variant = 'switch',
}) => {
	const switchButtonStyle = clsx(
		'smooth-hover',
		toggled ? 'bg-color-6 hover:bg-color-8' : 'bg-transparent border border-color-6 hover:border-color-8'
	)
	const optionButtonStyle = 'bg-color-6'

	const containerStyle = size === 'sm' ? 'h-7 min-w-12 w-20' : size === 'md' ? 'h-[30px] w-12' : 'h-10 min-w-32 w-24'
	const buttonToggleStyle = variant === 'switch' ? switchButtonStyle : optionButtonStyle
	const labelToggleStyle = clsx(
		variant === 'switch' && (toggled ? 'text-color-6' : 'text-color-4'),
		variant === 'option' && 'text-color-6'
	)
	return (
		// Container
		<button
			onClick={handleToggle}
			className={clsx(
				containerStyle,
				'flex relative rounded-full items-center bg-color-1 border border-color-3 hover:border-color-8 smooth-hover outline-none focus:border-color-8 focus:border-2'
			)}
		>
			{/* Sliding window for label */}
			<div
				className={clsx(
					'flex items-center w-full h-full justify-end',
					transitionStyle,
					toggled ? '-translate-x-full' : 'translate-x-0'
				)}
			>
				<div className={clsx(transitionStyle, toggled ? 'translate-x-full' : 'translate-x-0')}>
					{/* Label */}
					<span
						className={clsx(
							'select-none leading-none my-auto flex',
							labelToggleStyle,
							labelPadding,
							size === 'sm' && 'text-[11px] font-semibold',
							size === 'lg' && 'text-lg font-normal'
						)}
					>
						{toggled ? toggleOnLabel : toggleOffLabel}
					</span>
				</div>
			</div>
			{/* Sliding window for button */}
			<div
				className={clsx(
					'flex items-center w-full h-full justify-start',
					transitionStyle,
					toggled ? 'translate-x-full' : 'translate-x-0'
				)}
			>
				{/* Wrapper for Button */}
				<div className={clsx(transitionStyle, toggled ? '-translate-x-full' : 'translate-x-0')}>
					{/* Button */}
					<span
						className={clsx(
							'flex shrink-0 rounded-full',
							buttonToggleStyle,
							buttonPadding,
							size === 'sm' && 'w-4 h-4',
							size === 'md' && 'w-5 h-5',
							size === 'lg' && 'w-[45px] h-[45px]'
						)}
					/>
				</div>
			</div>
		</button>
	)
}
