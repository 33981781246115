import { Web3Provider } from '@ethersproject/providers'
import * as Sentry from '@sentry/nextjs'
import Decimal from 'decimal.js'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { TokenOrStaticToken } from 'pages/airdrop'
import Web3Modal from 'web3modal'
import { IAppContext } from '../components/app-context'
import erc721 from '../contracts/abis/erc721.json'
import nftMarketplaceABI from '../contracts/abis/nftMarketplaceABI.json'
import tokenLinearBondedEthDeployerABI from '../contracts/abis/TokenLinearBondedEthDeployer.json'
import { CoinviseABI__factory, ERC721GCR__factory, TokenDeployer__factory } from '../contracts/abis/types'
import {
	AddEthereumChainParameter,
	ContractsEnum,
	FollowProfile,
	NetworkName,
	Nullable,
	User,
} from '../interfaces/interfaces'
import { shortenAddress, shortenAddressWithNoDots } from '../utils'
import { GCRNft } from './constants'
import { ensNamesToAddresses } from './queryFunctions'

export const getTypedMessage = (
	name: ContractName,
	version: string,
	nonce: any,
	address: string,
	functionSignature: string,
	contractAddress: string,
	chainId: number
): TypedMessage => {
	return {
		domain: {
			name,
			version,
			salt: chainId,
			verifyingContract: contractAddress,
		},
		primaryType: 'MetaTransaction',
		types: {
			EIP712Domain: [
				{ name: 'name', type: 'string' },
				{ name: 'version', type: 'string' },
				{ name: 'salt', type: 'uint256' },
				{ name: 'verifyingContract', type: 'address' },
			],
			MetaTransaction: [
				{ name: 'nonce', type: 'uint256' },
				{ name: 'from', type: 'address' },
				{ name: 'functionSignature', type: 'bytes' },
			],
		},
		message: {
			nonce: parseInt(nonce),
			from: address,
			functionSignature,
		},
	}
}

export const verifySignature = (
	campaignManager: string,
	campaignId: number,
	userAddress: string,
	signature: ethers.Signature
): boolean => {
	const hash = ethers.utils.keccak256(
		ethers.utils.defaultAbiCoder.encode(['address', 'uint256', 'address'], [campaignManager, campaignId, userAddress])
	)
	const digest = ethers.utils.arrayify(hash)
	const recoveredAddress = ethers.utils.verifyMessage(digest, ethers.utils.joinSignature(signature))

	return recoveredAddress === process.env.NEXT_PUBLIC_TRUSTED_ADDRESS
}

// TODO: Convert to a hook
export const executeMetaTx = async (
	contractName: ContractName,
	contractVersion: string,
	context: IAppContext,
	fnName: string,
	fnParams: (string | number | boolean | Decimal | ethers.BigNumber)[]
): Promise<any> => {
	const signer = context.walletProvider.getSigner()
	const userAddress = await signer.getAddress()
	let contractAddress = ''
	let abi: any

	switch (contractName) {
		case 'Coinvise':
			contractAddress = contractAddresses.coinvise[context.chainId.toString()]
			abi = CoinviseABI__factory.abi
			break
		case 'CoinviseTokenDeployer':
			contractAddress = contractAddresses.tokenDeployer[context.chainId.toString()]
			abi = TokenDeployer__factory.abi
			break
		case 'CoinviseTokenLinearBondedEthDeployer':
			contractAddress = contractAddresses.tokenLinearBondedEthDeployer[context.chainId.toString()]
			abi = tokenLinearBondedEthDeployerABI
			break
		case 'ERC721Token':
			contractAddress = contractAddresses.erc721[context.chainId.toString()]
			abi = erc721
			break
		case 'erc721NFTMiami':
			contractName = 'ERC721Token'
			contractAddress = contractAddresses.erc721NFTMiami[context.chainId.toString()]
			abi = erc721
			break
		case 'CoinviseNftMarketplace':
			contractAddress = contractAddresses.nftMarketplace[context.chainId.toString()]
			abi = nftMarketplaceABI
			break

		// case 'NFTAirdrop':
		// 	contractAddress = contractAddresses.nftAirdrop[context.chainId.toString()]
		// 	abi = nftAirdropABI
		// 	break

		case 'ERC721GCR':
			contractAddress = GCRNft
			abi = ERC721GCR__factory.abi
			break

		default:
			break
	}

	if (!contractAddress || !context.biconomy) throw new Error('Failed to fetch details for the transaction')

	const contract = new ethers.Contract(contractAddress, abi, context.biconomy.getSignerByAddress(userAddress))
	const contractInterface = new ethers.utils.Interface(abi)

	const userNonce = await contract.getNonce(userAddress)
	const functionSignature = contractInterface.encodeFunctionData(fnName, fnParams)

	const typedMessage = getTypedMessage(
		contractName,
		contractVersion,
		userNonce,
		userAddress,
		functionSignature,
		contractAddress,
		context.chainId
	)

	const signature = (await context.walletProvider.send('eth_signTypedData_v4', [
		userAddress,
		JSON.stringify(typedMessage),
	])) as string
	const { r, s, v } = ethers.utils.splitSignature(signature)

	return contract.executeMetaTransaction(userAddress, functionSignature, r, s, v)
}

export const getAddChainParams = (chainId: number): Nullable<AddEthereumChainParameter> => {
	switch (chainId) {
		case 1:
			return {
				chainName: 'Ethereum Network',
				chainId: ethers.utils.hexValue(1),
				nativeCurrency: {
					name: 'Eth',
					symbol: 'ETH',
					decimals: 18,
				},
				rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
				blockExplorerUrls: ['https://etherscan.io'],
			}

		case 3:
			return {
				chainName: 'Ropsten Network',
				chainId: ethers.utils.hexValue(3),
				nativeCurrency: {
					name: 'Eth',
					symbol: 'ETH',
					decimals: 18,
				},
				rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
				blockExplorerUrls: ['https://ropsten.etherscan.io'],
			}

		case 5:
			return {
				chainName: 'Goerli Network',
				chainId: ethers.utils.hexValue(5),
				nativeCurrency: {
					name: 'Eth',
					symbol: 'ETH',
					decimals: 18,
				},
				rpcUrls: [rpcList[5]],
				blockExplorerUrls: [getChainBlockExplorer(5)],
			}

		case 137:
			return {
				chainName: 'Matic Network',
				chainId: ethers.utils.hexValue(137),
				nativeCurrency: {
					name: 'Matic',
					symbol: 'MATIC',
					decimals: 18,
				},
				rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
				blockExplorerUrls: ['https://polygonscan.com'],
			}

		case 80001: {
			return {
				chainName: 'Mumbai Testnet',
				chainId: ethers.utils.hexValue(80001),
				nativeCurrency: {
					name: 'Matic',
					symbol: 'MATIC',
					decimals: 18,
				},
				rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
				blockExplorerUrls: ['https://mumbai.polygonscan.com'],
			}
		}

		default:
			return null
	}
}

export interface ParseAddressRes {
	data: { inputAddresses: string[]; inputAmounts: number[] }
	duplicateAddresses: string[]
	invalidAddresses: string[]
}

interface ParseAddressOptions {
	addressesWithAmount?: string
	allowDuplicate?: boolean
}

export const parseAddresses = async ({
	allowDuplicate = false,
	addressesWithAmount,
}: ParseAddressOptions = {}): Promise<ParseAddressRes> => {
	const delimiter = ','
	const addressesWithAmountArr = addressesWithAmount.split('\n')
	const inputAmounts: number[] = []
	const duplicateAddresses: string[] = []
	const invalidAddresses: string[] = []

	const inputAddresses: string[] = []

	const resolvedEnsCache: Record<string, string> = {}

	for (let i = 0; i < addressesWithAmountArr.length; i++) {
		const row = addressesWithAmountArr[i]
		const addressWithAmount = row.split(delimiter).map((item) => item.trim())

		if (addressWithAmount.length !== 2) {
			invalidAddresses.push(row)
			continue
		}

		const currentAddress = addressWithAmount[0]
		const currentAmount = Number(addressWithAmount[1])

		/**
		 * `-1` if not a duplicate
		 */
		const duplicateIndex = inputAddresses.indexOf(currentAddress)

		if (!allowDuplicate && duplicateIndex !== -1) {
			// duplicateAddresses.push(currentAddress)
			duplicateAddresses.push(row)
			continue
		}

		if (currentAddress.includes('.')) {
			let resolvedAddr: string
			if (resolvedEnsCache[currentAddress]) {
				resolvedAddr = resolvedEnsCache[currentAddress]
			} else {
				try {
					// const ethProvider = new ethers.providers.JsonRpcProvider(rpcList[1])
					resolvedAddr = await ensNamesToAddresses([currentAddress]).then((res) => res[0]?.address)
				} catch (error) {
					console.error(error)
					invalidAddresses.push(currentAddress)
					continue
				}
			}

			// ENS could not be resolved
			if (!resolvedAddr) {
				invalidAddresses.push(currentAddress)
				continue
			}

			const duplicateEnsIndex = inputAddresses.indexOf(resolvedAddr)
			if (!allowDuplicate && duplicateEnsIndex !== -1) {
				// duplicateAddresses.push(currentAddress)
				duplicateAddresses.push(row)
				continue
			}

			if (allowDuplicate && duplicateEnsIndex !== -1) {
				inputAmounts[duplicateEnsIndex] += currentAmount
				continue
			} else {
				inputAddresses.push(resolvedAddr)
				inputAmounts.push(currentAmount)
			}

			continue
		}

		if (!ethers.utils.isAddress(currentAddress) || isNaN(currentAmount) || currentAmount <= 0) {
			invalidAddresses.push(currentAddress)
			continue
		}

		if (allowDuplicate && duplicateIndex !== -1) {
			inputAmounts[duplicateIndex] += currentAmount
		} else {
			inputAddresses.push(currentAddress)
			inputAmounts.push(currentAmount)
		}
	}

	return { data: { inputAddresses, inputAmounts }, duplicateAddresses, invalidAddresses }
}

const manageDuplicateAddress = (
	array: AddressAmountMapping[],
	effectedArray: AddressAmountMapping[],
	duplicateAddresses: string[]
): AddressAmountMapping[] => {
	for (let i = 0; i < array.length; i++) {
		const { address, amount } = array[i]

		const duplicate = effectedArray.find((item) => item.address === address)

		if (duplicate) {
			// Pushes only if duplicate doesn't exist in the duplicate array
			if (duplicateAddresses.indexOf(address) === -1) {
				duplicateAddresses.push(address)
			}
			effectedArray = effectedArray.map((item) => {
				if (item.address === address) {
					return {
						address,
						amount: amount + duplicate.amount,
					}
				}
				return item
			})
			continue
		}

		effectedArray.push({
			address,
			amount,
		})
	}

	return effectedArray
}

interface AddressAmountMapping {
	address: string
	amount: number
}

export const parseAddressesEns = async ({
	addressesWithAmount,
}: ParseAddressOptions = {}): Promise<ParseAddressRes> => {
	const delimiter = ','
	const addressesWithAmountArr = addressesWithAmount.split('\n').filter(Boolean)

	/**
	 * String arrays
	 */
	const invalidAddresses: string[] = []
	const duplicateAddresses: string[] = []

	/**
	 * AddressAmountMapping array
	 */
	const rawEnsObjArray: AddressAmountMapping[] = []
	let addressObjArray: AddressAmountMapping[] = []
	let ensObjArray: AddressAmountMapping[] = []

	/**
	 * Raw address array object with no invalid addressess and ens
	 */
	const rawAddressArray: AddressAmountMapping[] = addressesWithAmountArr
		.map((item) => {
			const rawInput = item.split(delimiter).map((item) => item.trim())

			if (rawInput.length !== 2) {
				invalidAddresses.push(rawInput[0])

				return null
			}

			const [address, amount] = [rawInput[0], Number(rawInput[1])]

			if (address.includes('.')) {
				rawEnsObjArray.push({ address: address.toLowerCase(), amount })
				return
			} else {
				if (!ethers.utils.isAddress(address) || isNaN(amount) || amount < 0) {
					invalidAddresses.push(address)
					return
				}

				// Omit input if `0` found in amount
				if (amount === 0) {
					return null
				}
			}
			return {
				address,
				amount,
			}
		})
		.filter(Boolean)

	/**
	 * Creates an array of address with no duplicates but sums the duplicate amount and creates an duplicate array to keep track of duplicates
	 */
	addressObjArray = manageDuplicateAddress(rawAddressArray, addressObjArray, duplicateAddresses)

	/**
	 * Creates an array of Ens with no duplicates but sums the duplicate amount and pushes duplicates in duplicate array
	 */
	ensObjArray = manageDuplicateAddress(rawEnsObjArray, ensObjArray, duplicateAddresses)

	const resolvedEnsArray = await ensNamesToAddresses(ensObjArray.map((item) => item.address))

	/**
	 * Replaces ens with resolved address if it exists else removes invalid ens
	 */
	ensObjArray = ensObjArray
		.map((ensObjItem) => {
			const domain = resolvedEnsArray.find((domain) => domain.address === ensObjItem.address)
			if (domain === undefined || !domain?.address) {
				invalidAddresses.push(ensObjItem.address)
				return null
			}
			const resolvedAddress = domain.address

			/**
			 * Checks for Duplicate among resolved ens and previous addresses
			 */
			const duplicate = addressObjArray.find((item) => item.address.toLowerCase() === resolvedAddress.toLowerCase())

			if (duplicate) {
				if (duplicateAddresses.indexOf(ensObjItem.address) === -1) {
					duplicateAddresses.push(ensObjItem.address)
				}

				addressObjArray = addressObjArray.map((i) => {
					if (i.address.toLowerCase() === resolvedAddress.toLowerCase()) {
						return {
							address: resolvedAddress,
							amount: i.amount + ensObjItem.amount,
						}
					}
					return ensObjItem
				})
				return null
			}

			return {
				address: resolvedAddress,
				amount: ensObjItem.amount,
			}
		})
		.filter(Boolean)

	const [inputAddresses, inputAmounts] = [
		[...addressObjArray.map((i) => i.address), ...ensObjArray.map((i) => i.address)],
		[...addressObjArray.map((i) => i.amount), ...ensObjArray.map((i) => i.amount)],
	]

	return { data: { inputAddresses, inputAmounts }, duplicateAddresses, invalidAddresses }
}

export interface ParseAddressWithoutAmountsRes {
	addresses: string[]
	duplicateAddresses: string[]
	invalidAddresses: string[]
}

export const parseAddressesWithoutAmounts = async (
	addressesString: string,
	delimiter = '\n'
): Promise<ParseAddressWithoutAmountsRes> => {
	const duplicateAddresses = []
	const invalidAddresses = []

	// Get all addresses in a list
	const addresses = addressesString.split(delimiter).filter(Boolean)

	// Check addresses
	for (let i = 0; i < addresses.length; i++) {
		const address = addresses[i]

		// Collect duplicate addresses
		if (addresses.indexOf(address) !== i) {
			duplicateAddresses.push(address)
		}

		// Resolve if ens
		if (address.includes('.')) {
			const ethProvider = new ethers.providers.JsonRpcProvider(rpcList[1])
			try {
				const resolvedAddr = await ethProvider.resolveName(address)
				if (resolvedAddr) {
					addresses[i] = resolvedAddr
				} else {
					invalidAddresses.push(address)
				}
				continue
			} catch (error) {
				invalidAddresses.push(address)
				console.error(error)
				Sentry.captureException(error)
				continue
			}
		}

		// Check for valid addresses
		if (!ethers.utils.isAddress(address)) {
			invalidAddresses.push(address)
		}
	}

	return {
		addresses,
		invalidAddresses,
		duplicateAddresses,
	}
}

interface ParseEmailListData {
	emails: string[]
	duplicateEmails: number
	invalidEmails: number
}

export const parseEmailList = (emailList: string): ParseEmailListData => {
	const delimiter = '\n'
	const emailListArr = emailList.split(delimiter)
	let invalidEmails = 0

	const emailListArrTrimmed = emailListArr.map((email) => {
		const trimmedEmail = email.trim()
		if (!checkSimpleEmail(trimmedEmail)) {
			invalidEmails++
		}

		return trimmedEmail
	})

	const emailListArrTrimmedUnique = [...new Set(emailListArrTrimmed)]
	const duplicateEmails = emailListArrTrimmed.length - emailListArrTrimmedUnique.length

	return { emails: emailListArrTrimmedUnique, duplicateEmails, invalidEmails }
}

// TODO: check if this is correct
const checkSimpleEmail = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

type TypedMessage = {
	domain: { name: string; version: string; /*chainId: number;*/ salt: number; verifyingContract: string }
	primaryType: string
	types: { EIP712Domain: { name: string; type: string }[]; MetaTransaction: { name: string; type: string }[] }
	message: { nonce: number; from: string; functionSignature: string }
}

export const rpcList: { [k: number]: string } = {
	1: 'https://mainnet.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
	3: 'https://ropsten.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
	4: 'https://rinkeby.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
	5: 'https://goerli.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
	// 137: 'https://polygon-mainnet.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
	137: 'https://polygon-mainnet.g.alchemy.com/v2/SEJ4f0BIy0x1fCNEgI-Nt5adOvFq3TRD',
	80001: 'https://polygon-mumbai.infura.io/v3/aa079d178e3c44bb8dd492c3f99bee77',
}

export const gnosisAPIList: { [k: number]: string } = {
	1: 'https://safe-transaction.mainnet.gnosis.io/',
	4: 'https://safe-transaction.rinkeby.gnosis.io/',
	5: 'https://safe-transaction.goerli.gnosis.io/',
	137: 'https://safe-transaction.polygon.gnosis.io/',
}

export const addMaticNetwork = async (chainId: number, web3Provider: Web3Provider): Promise<void> => {
	const params = getAddChainParams(chainId)
	try {
		await web3Provider.send('wallet_addEthereumChain', [params])
	} catch (error) {
		console.log(error)
	}
}

export type ContractName =
	| 'Coinvise'
	| 'CoinviseTokenDeployer'
	| 'ERC721Token'
	| 'NftMarketplace'
	| 'CoinviseNftMarketplace'
	| 'NftProxy'
	| 'CoinviseTokenLinearBondedEthDeployer' // TODO: confirm
	// | 'NFTAirdrop'
	| 'erc721NFTMiami'
	| 'ERC20Token'
	| 'ERC721GCR'
	| 'NFTAirdrop'

export const contractNameToContractsEnum: Record<ContractName, ContractsEnum> = {
	Coinvise: ContractsEnum.coinvise,
	CoinviseNftMarketplace: ContractsEnum.nftMarketplace,
	CoinviseTokenDeployer: ContractsEnum.tokenDeployer,
	CoinviseTokenLinearBondedEthDeployer: ContractsEnum.tokenLinearBondedEthDeployer,
	ERC721Token: ContractsEnum.erc721,
	NftMarketplace: ContractsEnum.nftMarketplace,
	NftProxy: ContractsEnum.nftProxy,
	// NFTAirdrop: ContractsEnum.nftAirdrop,
	erc721NFTMiami: ContractsEnum.erc721NFTMiami,
	ERC20Token: ContractsEnum.erc20Token,
	ERC721GCR: ContractsEnum.erc721GCR,
	NFTAirdrop: ContractsEnum.NFTAirdrop,
}

// export type EmitEvent = 'Erc20TokenDeployed' | 'Approval'
// TODO: Use typechain instead of this
export enum EmitEvent {
	TokenDeployed = 'Erc20TokenDeployed',
	Approved = 'Approval',
	Multisent = 'Multisent',
	CampaignCreated = 'CampaignCreated',
	Withdrawn = 'Withdrawn',
	UserRewarded = 'UserRewarded',
	Transfer = 'Transfer',
	NftListed = 'NftListed',
	NftBought = 'NftBought',
	WithdrawnDepositOwnerBalance = 'WithdrawnDepositOwnerBalance',
	WithdrawnEthPremiums = 'WithdrawnEthPremiums',
	TokenMinted = 'TokenMinted',
	BulkTokensMinted = 'BulkTokensMinted',
	ApprovalForAll = 'ApprovalForAll',
}

export const createClaimLink = (slug: string): string => {
	return `${process.env.NEXT_PUBLIC_HOST}/claim/${slug}`
}

export const containsUser = (list: FollowProfile[], wallet_address: string): boolean => {
	for (let i = 0; i < list.length; i++) {
		if (list[i].wallet_address === wallet_address) {
			return true
		}
	}
	return false
}

export const getEthOrMatic = (chainId?: number): string => {
	switch (chainId) {
		case 80001:
		case 137:
			return 'MATIC'

		case 0:
		case 1:
		case 3:
		case 5:
			return 'ETH'

		default:
			return 'Unknown'
	}
}

export const getNetworkNameByChainId = (chainId: number | string): NetworkName => {
	let chain: number
	if (typeof chainId === 'string') {
		chain = Number(chainId)
	} else {
		chain = chainId
	}

	switch (chain) {
		case 80001:
			return 'mumbai'
		case 137:
			return 'polygon'
		case 1:
			return 'mainnet'
		case 5:
			return 'goerli'
		case 3:
			return 'ropsten'
		default:
			return 'unknown'
	}
}

// 1.5 for MATIC
// 0.0005 for ETH
export const getCampaignFee = (chainId: number): number => {
	if (checkIsEth(chainId)) {
		return 0.0005
	} else if ([137, 80001].includes(chainId)) {
		return 0.002
	} else {
		return 0
	}
}

export const getMultisendFee = (chainId: number): number => {
	if ([1, 3].includes(chainId)) {
		return 0.00025
	} else if ([137, 80001].includes(chainId)) {
		return 0.001
	}
}

export const getNftListingFee = (chainId: number): number => {
	if ([1, 3].includes(chainId)) {
		return 0
	} else if (chainId === 137) {
		return 10
	} else if (chainId === 80001) {
		return 0.01
	}
}

export const checkIsEth = (chainId: number | string): boolean =>
	[1, 3, 4, 5].includes(typeof chainId === 'string' ? Number(chainId) : chainId)

export const contractAddresses: Record<ContractsEnum, Record<string, string>> = {
	coinvise: {
		137: '0xeF5d154B20a4041493a0eA59872c10bE3bd8C1B0',
		80001: '0xeF37eC3352988408ff34E8eAA2351cf3555DB7e1',
		1: '0x06D95B328761a062fD200729e1C868147004bA44',
		3: '0x4Ee15B74124c9B3910af473c02e9C7080Ac0FD70',
		4: '0x0856eC92b7BbAA0bDE6F108122E214d70EfA4aa8',
	},
	tokenDeployer: {
		137: '0x52ccD2d33b38E1f141d9e0a72867E4Eb338A857e',
		80001: '0x1E9A9748b5B105143E768c27DcC6Bcf1Fb636901',
	},
	tokenLinearBondedEthDeployer: {
		137: '0x73dE04750C37eEE8CecBd5dA4656AB64968b00b9',
		80001: '0xF9A158dF8C61F1e4C3BB18a87054B8DD7421a385',
	},
	erc721: {
		1: '0xD2476AF3bA1928aC01ED068dfdf34598B6190155',
		3: '0xf578c79dDBdE90600a2CbDdA822Bead9ecd433A2',
		137: '0x194b8237506673e0EB174090F38E7c615FDF4C62',
		80001: '0x7C333356E6A0a1644F1307Da00348AaFfE5BBFa5',
	},
	nftMarketplace: {
		1: '0xb175F9058F70a8cb887126d6c598B5356047feB8',
		3: '0x9771248D9e60a06ec7861a02C7670FFE06452BeD',
		137: '0xa1F11230dFa8bAe22AC028E4977a00f5e3A82BCc',
		80001: '0x6b467Baf1590f737312F426c49EFd59bC255fb3E',
	},
	nftProxy: {
		1: '0xC68A19D0E17D3924Ed3abb8766CEbfE38F68B255',
		3: '0xaafACbEa22450545d48eba61f6d0f70621CE861f',
	},
	// nftAirdrop: {
	// 	1: '0x3dCee31e9Bd58BDD4898E6ecDC7F4E7dDc0aA65D',
	// 	3: '0xD3C099efD49C45fe63732642881CD67C2Bf4af15',
	// 	80001: '0x4b9a08Ea605B836976c1c4dd9970E349D75a9cfe',
	// 	137: '0x38EEa3b911DE4461161527b9E0aec1D4C389b76b',
	// },
	erc721NFTMiami: {
		1: '0x9d1DD5c21817C32cfC5e24B1af28c0b2b552a199',
		3: '0xedEa4f5c63709CD0b5bad7D7CB2669ED6250dC0e',
		80001: '0x84CE1014fbf5Bf010b82b1F013Dd066f62f31052',
		137: '0x0238A07d8414a118800fe80279C683a7Be29d829',
	},
	fxERC20RootTunnel: {
		1: '0x21D43549B8995627030d4F8C277fd9E7f759a1cE',
		5: '0x06D95B328761a062fD200729e1C868147004bA44',
	},
	multisend: {
		1: '0x22Bc0693163eC3CeE5DeD3c2EE55ddbCb2bA9bbe',
		3: '0x57eD61150D09EAD93714aa972e1D2f9037aB6be3',
		4: '0x5C36302eF3f392b671FF64Bf11b430497818196e',
		80001: '0x02B46AF4017B6f013B600b995f16659a702a3128',
		137: '0x973B99f902915528aC3ED4c0544E02C83fa2CEb0',
	},
	vestingFactory: {
		1: '0x60e6B4e7475CdFafef103d79617d80Ecd9767D72',
		3: '0x33f464A969B65090eA1fE212cE9182d9c2dbF9bD',
		80001: '0x67dC05E6c45b3a1e4349BB5f2a9C21477bC79b43',
		137: '0xA5912eFF588B6B4133a97cD15E6026F030b1B08b',
		4: '0x49c7771Eb3eD0e6C6de96746b0C3e51D479fF758',
	},
	erc20Token: {},
	erc721GCR: {},
	membershipsFactory: {
		80001: '0xE849364bf8297bd63A2765ae7fadf9BD79C6cd7f',
		3: '0xf95d2d81cb772797327b35d0dd5701f24ad6333d',
		137: '0xe98f10436bed1f5dbE0415B0c735aC8C88E72F8b',
		1: '0x28CE3fC02c3b361f449eA74E49716D17bf6325b5',
	},
	NFTAirdrop: {
		80001: '0xE631213eAC88aFDaB402676d8756d04664094848',
		3: '0xE631213eAC88aFDaB402676d8756d04664094848',
		137: '0xF7305F7d266FaE5b31DDc12cF7d0AB3f5CD06c09',
		1: '0x9771248D9e60a06ec7861a02C7670FFE06452BeD',
	},
}

export const WebSocketsRPCList: { [k: number]: string } = {
	1: 'wss://mainnet.infura.io/ws/v3/aa079d178e3c44bb8dd492c3f99bee77',
	3: 'wss://ropsten.infura.io/ws/v3/aa079d178e3c44bb8dd492c3f99bee77',
	4: 'wss://rinkeby.infura.io/ws/v3/aa079d178e3c44bb8dd492c3f99bee77',
	5: 'wss://goerli.infura.io/ws/v3/aa079d178e3c44bb8dd492c3f99bee77',
	137: 'wss://polygon-mainnet.g.alchemy.com/v2/1fb8mPS-CiUB7Qe5Zvlj_SIDZTli_2Gw',
	80001: 'wss://polygon-mumbai.g.alchemy.com/v2/R-wxJeM0HyX0zokuTQAHv-vJ9r1o7szv',
}

export const biconKeyList: { [k: number]: string } = {
	137: process.env.NEXT_PUBLIC_BICONOMY_KEY_MATIC,
	80001: process.env.NEXT_PUBLIC_BICONOMY_KEY_MUMBAI,
	3: process.env.NEXT_PUBLIC_BICONOMY_KEY_ROPSTEN,
	1: process.env.NEXT_PUBLIC_BICONOMY_KEY_MAINNET,
}

export const getUsername = (
	user: User | FollowProfile,
	full = false,
	chars = 4,
	noDots = false,
	endingChars = 0
): string =>
	user.user_name ||
	(full
		? user.wallet_address
		: noDots
		? shortenAddressWithNoDots(user.wallet_address, chars)
		: shortenAddress(user.wallet_address, chars, endingChars))

export const getDisplayName = (
	user: User | FollowProfile,
	full = false,
	chars = 4,
	noDots = false,
	endingChars = 0
): string =>
	user.name ||
	(full
		? user.wallet_address
		: noDots
		? shortenAddressWithNoDots(user.wallet_address, chars)
		: shortenAddress(user.wallet_address, chars, endingChars))

export const getTokenDisplayName = (token: TokenOrStaticToken, full = false): string =>
	token.symbol ?? token.name ?? (full ? token.address : shortenAddress(token.address, 4))

export const getChainBlockExplorer = (chainId?: string | number): string | undefined => {
	if (!chainId) return undefined

	switch (stringOrNumtoNum(chainId)) {
		case 1:
			return 'https://etherscan.io/'
		case 5:
			return 'https://goerli.etherscan.io/'
		case 137:
			return 'https://polygonscan.com/'
		case 3:
			return 'https://ropsten.etherscan.io/'
		case 4:
			return 'https://rinkeby.etherscan.io/'
		case 80001:
			return 'https://mumbai.polygonscan.com/'
		default:
			return undefined
	}
}

export const getAddressLink = (address: string, chainId: string | number): string => {
	if (!address) return ''
	return getChainBlockExplorer(chainId) + 'address/' + address
}

export const getChainBlockExplorername = (chainId?: string | number): string | undefined => {
	if (!chainId) return undefined

	switch (stringOrNumtoNum(chainId)) {
		case 1:
		case 3:
		case 4:
		case 5:
			return 'Etherscan'
		case 137:
		case 80001:
			return 'Polygonscan'
		default:
			return ''
	}
}

export const getChainBlockExplorerButtonMessage = (chainId?: string): string => {
	if (!chainId) return 'Unknown Chain'

	if ([1, 3, 5].includes(Number(chainId))) return 'View on Etherscan'
	else if ([137, 80001].includes(Number(chainId))) return 'View on Polygonscan'
	else return 'Unknown Chain'
}

export const parseTokenSymbol = (symbol: string): string => {
	if (symbol?.startsWith('$')) return symbol
	else return `$${symbol}`
}

export const getLiquidityProviderLink = (token: string, chainId: number): string => {
	if ([1, 3].includes(chainId)) return `https://app.uniswap.org/#/add/${token}`
	else if ([137, 80001].includes(chainId)) return `https://quickswap.exchange/#/add/${token}`
	else return ''
}

export const getStringFromStringOrArray = (value: string | string[]): string | null => {
	if (!value) return null
	if (Array.isArray(value)) return value[0]
	return value
}

export const stringOrNumtoNum = (value: string | number): number => (typeof value === 'string' ? Number(value) : value)

export const isMatic = (chainid: string | number): boolean =>
	[137, 80001].includes(typeof chainid === 'string' ? Number(chainid) : chainid)

export const getDaiAddress = (chainid: string | number): string => {
	switch (typeof chainid === 'string' ? Number(chainid) : chainid) {
		case 1:
			return '0x6b175474e89094c44da98b954eedeac495271d0f'
		case 3:
			return '0x31F42841c2db5173425b5223809CF3A38FEde360'
		case 137:
			return '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
		case 80001:
			return '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
	}
}

export const getUsdcAddress = (chainid: string | number): string => {
	switch (typeof chainid === 'string' ? Number(chainid) : chainid) {
		case 1:
			return '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
		case 3:
			return '0x07865c6E87B9F70255377e024ace6630C1Eaa37F'
		case 137:
			return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
		case 80001:
			return '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
	}
}

/**
 * Small util to compare balance and amount
 * @param balance Current balance of user
 * @param _amount The amount to be compared with balance
 * @param decimals Decimals used for the token
 * @returns
 */
export const hasEnoughFunds = (
	balance: string | BigNumber,
	_amount: Decimal.Value | BigNumber,
	decimals?: Decimal.Value
): boolean => {
	// TODO: return true?
	if (!balance || !_amount) return false
	const amount = _amount instanceof BigNumber ? new Decimal(_amount.toString()) : _amount

	if (balance instanceof BigNumber) {
		return new Decimal(balance.toString()).div(decimals ?? 1e18).gte(amount)
	}

	return new Decimal(balance).div(decimals ?? 1e18).gte(amount)
}

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))

interface FormatUnitsTruncatedOptions {
	decimals?: number
	/**
	 * the number of decimal places to truncate to
	 */
	trunateTo?: number
}

/**
 *
 * @param wei Amount in wei
 * @param options {FormatUnitsTruncatedOptions} formatting options
 * @returns formatted amount
 */
export const formatUnitsTruncated = (
	wei: BigNumberish,
	{ decimals = 18, trunateTo = 4 }: FormatUnitsTruncatedOptions = {}
): string => {
	if (!wei) return '0'

	const weiBN = BigNumber.from(wei)
	const remainder = weiBN.mod(BigNumber.from(10).pow(decimals - trunateTo))
	const formatedString = formatUnits(weiBN.sub(remainder), decimals)

	// remove decimals if ends with .0
	if (Number(formatedString.split('.')[1]) === 0) {
		return formatedString.split('.')[0]
	}

	return formatedString
}

export const disableWeb3ModalCache = (web3Modal: Web3Modal): void => {
	if ((web3Modal as any)?.providerController?.shouldCacheProvider) {
		try {
			;(web3Modal as any).providerController.shouldCacheProvider = false
		} catch (error) {
			console.warn('Failed to disable web3 modal cache')
			Sentry.captureException(error)
		}
	}
}

export const checkIfIPFS = (url: string): string => {
	if (url.slice(0, 7) === 'ipfs://') return `https://ipfs.io/ipfs/${url.slice(7)}`
	return url
}
