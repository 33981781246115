import { Biconomy } from '@biconomy/mexa'
import ConnectWalletModal from '@components/connect-wallet/ConnectWalletModal'
import { useConfetti } from '@components/core'
import { Web3Provider } from '@ethersproject/providers'
import Safe from '@gnosis.pm/safe-core-sdk'
import EthersAdapter from '@gnosis.pm/safe-ethers-lib'
import SafeServiceClient from '@gnosis.pm/safe-service-client'
import useCurrentUser from '@hooks/useCurrentUser'
import useLocalStorage from '@hooks/useLocalStorage'
import * as Sentry from '@sentry/nextjs'
import confetti from 'canvas-confetti'
import { ethers } from 'ethers'
import { isProduction, LocalStorage } from 'helpers/constants'
import { biconKeyList, gnosisAPIList } from 'helpers/utils'
import { walletconnectProvider, web3ModalProviderOptions } from 'lib/wallet'
import { useRouter } from 'next/router'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import Web3Modal from 'web3modal'
import AppContext, { IAppContext } from '../app-context'

interface AppSetters {
	setWalletProvider: (web3Provider: Web3Provider) => void
	setSelectedMenuKey: (key: string) => void
	setChainId: (chainId: number) => void
	setMaticProvider: (web3Provider: Web3Provider) => void
	setSafeSdk: (safeSdk: Safe) => void
	setConfettiRef: (instance: confetti.CreateTypes) => void
	fireConfetti: () => void
}

const AppSetterContext = createContext<AppSetters | undefined>(undefined)
export const useAppSetters = (): AppSetters => {
	const context = useContext(AppSetterContext)
	if (!context) {
		throw new Error('useAppSetters must be used within a AppProvider')
	}

	return context
}

const AppProvider: FC = ({ children }) => {
	const router = useRouter()
	const { data: user } = useCurrentUser()

	/**
	 * States
	 */
	const [chainId, setChainId] = useState(0)
	const [maticProvider, setMaticProvider] = useState<Web3Provider>()
	const [selectedMenuKey, setSelectedMenuKey] = useState<string>('home')
	const [walletconnectURI, setWalletconnectURI] = useState<string>('')

	const [gnosisAddress] = useLocalStorage(`${LocalStorage.GNOSIS}__${chainId}`, null)

	/**
	 * `provider` is what we get when we connect to a wallet
	 * Needed for listening to some events and building instantiating `walletProvider`
	 */
	const [provider, setProvider] = useState<any>(null)

	/**
	 * ethers compatible provider -- used for all interactions with the blockchain
	 */
	const [walletProvider, setWalletProvider] = useState<Web3Provider | null>(null)

	/**
	 * Gnosis safe related states
	 */
	const [safeSdk, setSafeSdk] = useState<Safe | null>(null)

	/**
	 * Init web3 modal
	 */
	const isBrowser = typeof window !== 'undefined'
	const web3Modal = useMemo(() => {
		if (!isBrowser) return

		return new Web3Modal({
			cacheProvider: true, // optional
			providerOptions: web3ModalProviderOptions, // required
		})
	}, [isBrowser])

	/**
	 * Event handler when wallet connect URI is generated
	 */
	useEffect(() => {
		if (!walletconnectProvider) return

		walletconnectProvider.connector.on('display_uri', (err, payload) => {
			if (err) {
				console.warn('Error in generating walletconnect URI', err)
				return
			}

			const _uri = payload.params[0]
			console.log('wallet connect URI generated:', _uri)
			setWalletconnectURI(_uri)
			// TODO: if user exists here, the open the connect wallet modal
		})
	}, [])

	/**
	 * Initialize web3 provider
	 * Automatically connects to wallet if "provider" is cached and user is logged in
	 * TODO: what happens if user is logged in but provider is not cached?
	 */
	useQuery('web3modal', () => web3Modal.connect(), {
		enabled: Boolean(user && web3Modal?.cachedProvider),
		staleTime: Infinity,
		cacheTime: 0,
		onSuccess: async (_provider) => {
			setProvider(_provider)

			const web3Provider = new Web3Provider(_provider)
			setWalletProvider(web3Provider)

			const network = await web3Provider.getNetwork()
			setChainId(network.chainId)
		},
	})

	/**
	 * Handle provider events
	 // TODO: https://docs.cloud.coinbase.com/wallet-sdk/docs/web3modal#access-connection-account-network-information
	 */
	useEffect(() => {
		if (!provider?.on) return

		provider.on('chainChanged', (chainId: string /* hex */) => {
			// console.log(Number(chainId))
			console.log('CHAIN CHANGED: ', Number(chainId))
			setChainId(Number(chainId))

			// console.info('Reloading because of chain change')
			// // TODO: can we do something less "hacky" than this?
			// router.reload()
		})

		// TODO: Will these suffice?
		provider.on('accountsChanged', () => {
			setProvider(null)
			setWalletProvider(null)
			setMaticProvider(null)
		})

		return () => {
			// TODO:
			provider.off?.('chainChanged', () => void 0)
			provider.off?.('accountsChanged', () => void 0)
		}
	}, [provider, router, setMaticProvider, setWalletProvider])

	const biconomy = useMemo(() => {
		if (!walletProvider) return null
		const biconomyKey = biconKeyList[chainId]
		if (!biconomyKey) {
			console.warn('Biconomy key not found')
			return null
		}

		return new Biconomy(walletProvider, {
			apiKey: biconomyKey,
			debug: !isProduction,
		})
	}, [chainId, walletProvider])

	/**
	 * Initialize Biconomy
	 */
	useEffect(() => {
		if (!biconomy) return

		// TODO: ask biconomy for the "off" to remove event listener
		biconomy
			.onEvent(biconomy.READY, () => {
				const biconomyWeb3 = new Web3Provider(biconomy)
				setMaticProvider(biconomyWeb3)
			})
			.onEvent(biconomy.ERROR, (error, message) => {
				Sentry.captureException(error, (scope) => scope.setExtras({ message }))
			})
	}, [setMaticProvider, biconomy])

	/**
	 * Init Gnosis Safe SDK
	 */
	useEffect(() => {
		if (!walletProvider || !chainId) return

		/**
		 * `gnosisAddress` comes from local storage
		 */
		if (!gnosisAddress) {
			if (safeSdk) {
				// if gnosisAddress was removed, remove the SDK too
				setSafeSdk(null)
			}

			return
		}

		;(async () => {
			// Already initialized
			if (safeSdk && safeSdk.getAddress() === gnosisAddress) return

			const ethAdapter = new EthersAdapter({
				ethers,
				signer: walletProvider.getSigner(),
			})

			try {
				const sdk = await Safe.create({
					ethAdapter: ethAdapter,
					safeAddress: gnosisAddress,
				})

				if ((await sdk.getChainId()) === chainId) {
					setSafeSdk(sdk)
				} else {
					console.warn('Safe address is on a different chain')
					return
				}
			} catch (error) {
				/**
				 * probably safe address is not a gnosis safe
				 * likely the user changed the local storage manually
				 * TODO: should probably be a way to know if the error is about wrong safe
				 * @see {@link https://github.com/gnosis/safe-core-sdk/issues/72}
				 */
				console.warn('Safe address is not gnosis safe on the current chain', error)
			}
		})()
	}, [walletProvider, chainId, gnosisAddress, safeSdk])

	/**
	 * Init Gnosis SafeServiceClient
	 */
	const safeService = useMemo(() => {
		if (!chainId || safeService) return

		const gnosisUrl = gnosisAPIList[chainId]
		if (!gnosisUrl) return null

		return new SafeServiceClient(gnosisUrl)
	}, [chainId])

	const appContext: IAppContext = useMemo(
		() => ({
			web3Modal,
			chainId,
			biconomy,
			selectedMenuKey,
			maticProvider,
			walletProvider,
			walletconnectURI,
			safeSdk,
			safeService,
		}),
		[
			biconomy,
			chainId,
			maticProvider,
			walletProvider,
			web3Modal,
			selectedMenuKey,
			walletconnectURI,
			safeSdk,
			safeService,
		]
	)

	const { setRef: setConfettiRef, fire: fireConfetti } = useConfetti()

	const appSetterValue: AppSetters = useMemo(
		() => ({
			setWalletProvider,
			setSelectedMenuKey,
			setChainId,
			setMaticProvider,
			setSafeSdk,
			setConfettiRef,
			fireConfetti,
		}),
		[setSelectedMenuKey, setChainId, setWalletProvider, setMaticProvider, setSafeSdk, setConfettiRef, fireConfetti]
	)

	return (
		<AppContext.Provider value={appContext}>
			<AppSetterContext.Provider value={appSetterValue}>
				<ConnectWalletModal />
				{children}
			</AppSetterContext.Provider>
		</AppContext.Provider>
	)
}

export default AppProvider
