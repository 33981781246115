import { FC } from 'react'

// Fill
export const WalletIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.6 0.394531H2.1C0.9422 0.394531 0 1.33673 0 2.49453V10.8945C0 12.0523 0.9422 12.9945 2.1 12.9945H12.6C13.3721 12.9945 14 12.3666 14 11.5945V1.79453C14 1.02243 13.3721 0.394531 12.6 0.394531ZM2.1 11.5945C1.7136 11.5945 1.4 11.2802 1.4 10.8945V2.49453C1.4 2.10883 1.7136 1.79453 2.1 1.79453H12.6V3.89453H8.4C7.6279 3.89453 7 4.52243 7 5.29453V8.09453C7 8.86663 7.6279 9.49453 8.4 9.49453H12.6007V11.5945H2.1ZM12.6 5.29453V8.09453H8.4V5.29453H12.6Z" />
		</svg>
	)
}
